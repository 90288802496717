import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ModalStackManager from "./components/Modal/ModalStackManager";
import GlobalStyle from "./style/Global";
import { Loading } from "components/Loading";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "components/Error";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true, // 모든 쿼리에 대해 suspense 활성화
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MantineProvider withGlobalStyles>
      <GlobalStyle />
      <Suspense fallback={<Loading />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <QueryClientProvider client={queryClient}>
            <ModalStackManager>
              <App />
            </ModalStackManager>
          </QueryClientProvider>
        </ErrorBoundary>
      </Suspense>
    </MantineProvider>
  </React.StrictMode>
);
