import { Container, Card, Text } from "@mantine/core";

export const NotFound = () => {
  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        shadow="sm"
        padding="lg"
        style={{
          maxWidth: 640,
          textAlign: "center",
        }}
        bg="#F2F2F2"
      >
        <Text weight={700} size="xl" mt="md">
          올바르지 않은 접근 방식입니다.
        </Text>
        {/* <Text weight={700} size="xl" mt="md">
          요청하신 접수건을 찾을 수 없습니다.
        </Text>
        <Text size="sm" color="gray" mt="xs">
          입력하신 주소가 올바른지 확인해 주세요.
        </Text>
        <Text size="sm" color="gray" mt="xs">
          접수건이 삭제되었거나,
        </Text>
        <Text size="sm" color="gray">
          접근이 제한된 경우일 수도 있습니다.
        </Text> */}
      </Card>
    </Container>
  );
};
